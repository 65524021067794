import ballImg from '../files/Ball_ParticleSystem.png'
// import waldoImg from '../files/waldo.png'
import React from 'react'
import Sketch from 'react-p5'



const Fr = 30;

const w = window.innerWidth; //800; //props.pWidth;
const h = 350; //props.pHeight;
let myImg: any;
const myImgWidth = 100;
let nParticles = 10;    //15;  // Number of particles generated each frame
let nParticles2;
let particles: any[] = [];
let removeIndex = nParticles + 1;
let index = 1;

class Particle {
  x: number; y: number; vx: number; vy: number; 
  alpha: number; color: number; size: number; 

  constructor () {
    this.x = Math.random()*(w-100) + 50 ;
    this.y = Math.random()*(h-100) + 50;
    this.vx = Math.random()*6 - 1.5;
    this.vy = Math.random()*5 - 2;
    this.alpha = 100;
    this.color = 255;  //this.color = random(200, 255);
    this.size = Math.random()*60+20;
  }
}


export default function P5Sketch(props: any) {

    // const preload = (p5) => {
    //   sketchFont = p5.loadFont('../fonts/Comforter-Regular.ttf');
    // }

    const setup = (p5: any, canvasParentRef: any) => {
        p5.createCanvas(w, h).parent(canvasParentRef);
        p5.frameRate(Fr);
        myImg = p5.loadImage(ballImg);
        //myWaldoImg = p5.loadImage(waldoImg);

        for (let i=0; i<nParticles; i++){
          particles[i] = new Particle();
        }
        nParticles2 = nParticles;
       // p5.textFont(sketchFont);
        p5.textSize(50);
    }
      
    const draw = (p5: any) => {
        //console.log(`Window Width: `, w);
        p5.background(`rgba(0, 0, 0, 1)`);


        for (let i=0; i<particles.length; i++){
          // Wall conditions
          if (particles[i].x < 0 || particles[i].x > w -50) {
            particles[i].vx = -particles[i].vx;
          }
          if (particles[i].y > h -50 || particles[i].y < 0) {
            particles[i].vy = -particles[i].vy;
          }

          // Plotting the meatballs
          particles[i].x += particles[i].vx;
          particles[i].y += particles[i].vy;

          // identify and remove particle is mouse is over bounding box.
          if (
            p5.mouseX >= particles[i].x &&
            p5.mouseX <= particles[i].x + 40 &&
            p5.mouseY >= particles[i].y &&
            p5.mouseY <= particles[i].y + 40
          ) {
            removeIndex = i;
          }





          // if (i == 0) {
          //   p5.image(myWaldoImg, particles[i].x, particles[i].y, myImgWidth / 3, myImgWidth / 3);
          // } else {
            p5.image(myImg, particles[i].x, particles[i].y, myImgWidth / 2, myImgWidth / 2);
            p5.noFill();
            p5.stroke(255);
            p5.ellipse(particles[i].x + 25, particles[i].y + 7, 18, 7);
         // }




          // p5.textSize(50);
          // p5.fill(255);
          // p5.text('One Drop NFTs Presents:', w/2 - w/4, h/4);

          // p5.textSize(25);
          // p5.stroke(155,0,155)
          // p5.fill(155,0,155);
          // p5.text('Art With a Soundtrack', w/2 - w/4, h/3);
        }

        p5.ellipse(p5.mouseX, p5.mouseY, 35, 35);

        particles.splice(removeIndex, 1);
        removeIndex = particles.length+1;
        
        if (particles.length < 1) {
          index = index*2;
          nParticles2 = nParticles*index;
          for (let i=0; i<nParticles2; i++){
            particles[i] = new Particle();
          }
          // if (finalFrame >= 4) {
          //   p5.textSize(20);
          //   p5.stroke(255,0,0)
          //   p5.fill(255,0,0);
          //   p5.text('Congratulations on extinguishing an entire species.', w/4, h-h/5);
          //   p5.text(`I hope you're proud of what you've done.`, w/4 + w/20, h-h/7);
          //   p5.noLoop();
            
          // }
          // finalFrame += 1;
        }
    }    
      
    return (
      <Sketch setup={setup} draw={draw} />
    )
}


// class Particle {

//   constructor() {
//     // Making a semicircle as initial position for the particles.
//     //this.r = r;
//     //this.theta = randomGaussian(1.5 * 3.1415, 0.5);
//     //this.x = w / 2 - this.r * cos(this.theta);
//     //this.y = h - h / 3 - this.r * sin(this.theta);

//     this.x = p5.randomGaussian(w / 2, 250);
//     this.y = h - h / 6;

//     // Determining the particle velcoities.
//     this.vx = p5.random(-1.5, 1.5);
//     this.vy = p5.random(-7, -2);
//     this.alpha = 100;
//     this.color = 255;  //this.color = random(200, 255);
//     this.size = p5.random(20, 80);
//   }

//   update() {
//     this.x += this.vx;
//     this.y += this.vy;
//     this.alpha -= 0.3; //random(0, 0.5);   // Amount of particle decay
//   }

//   show() {
//     p5.stroke(255, this.color, this.color, this.alpha);
//     p5.fill(255, this.color, this.color, this.alpha);
//     //ellipse(this.x, this.y, particleSize);  // Particle size
//     p5.ellipse(this.x, this.y, p5.random(20, 35));  // Particle size
//     //ellipse(this.x, this.y, this.size);  // Particle size

//   }

//   finished() {
//     return this.alpha < 0;
//   }

// }