// interface A {
//     a: string;
// }

// interface B {
//     b: number;
// }

export function musicDataParse (nftarr: Array<any>) {
//export function musicDataParse (nftarr) {
        let playerData = [];
    
        for (let i=0; i<nftarr?.length; i++) {
            // Filter all the music NFTs that abide by the music NFT standard.
            if ('track1' in nftarr[i]) {
        
                // Getting all the 'key' names from the metadata
                let mykeys = Object.keys(nftarr[i])
        
    
                // Filtering all the keys that contain 'track'
                let myTracks = mykeys.filter( (element) => {
                    if (element.indexOf('track') !== -1) {
                      return true;
                    } else {
                        return false;
                    }
                  });
                    // console.log(`Matching array: `, myTracks);
    
    
                // Create an array of data that contains the song information  
                let songData = []
                for (let k=0; k<myTracks.length; k++) {
                    songData.push(nftarr[i][myTracks[k]])
                }
                    // console.log(`Song Hash arr: `, songData)
        
    
                // Parse the song name from the song hash, 
                let songMusician  = []
                let songAlbum = []
                let songNames  = []
                let songHashes = []
                const musicianRegex = /.+(?=,,)/g;
                const albumRegex = /(?<=,,).+(?=::)/g;
                const songRegex = /(?<=::).+(?=&&)/g;
                const hashRegex = /(?<=&&).+/g;
    
        
                for (let l=0; l<songData.length; l++) {
                    // songMusician.push(songData[l]?.match(musicianRegex).toString().trim());
                    //    songAlbum.push(songData[l]?.match(albumRegex).toString().trim());
                    //   songHashes.push(songData[l]?.match(hashRegex).toString().trim());
                    //    songNames.push(songData[l]?.match(songRegex).toString().trim());
                       songMusician.push(songData[l]?.match(musicianRegex));
                       songAlbum.push(songData[l]?.match(albumRegex));
                      songHashes.push(songData[l]?.match(hashRegex));
                       songNames.push(songData[l]?.match(songRegex));
                }
                // https://{gateway URL}/ipfs/{content ID}/{optional path to resource}
                // https://ipfs.infura.io:5001/ipfs/bafybeifhlwcf7xjl4xddip72dg2byu2cf3idskmkxbive6vzf4kpjebecm
                // https://onedropnfts.infura-ipfs.io/ipfs/bafybeifhlwcf7xjl4xddip72dg2byu2cf3idskmkxbive6vzf4kpjebecm
                //songHashes = songHashes.map(url => 'https://onedropnfts.infura-ipfs.io/ipfs/' + url);
                songHashes = songHashes.map(url => 'https://dweb.link/ipfs/' + url);
        
    
                // Create the object that will be sent to the player component.
                playerData.push(
                    {
                        musician: songMusician,
                        album: songAlbum,
                        tracks: songNames,
                        ipfsURLs: songHashes,
                        imageURLs: nftarr[i]?.image
                    }
                );
        
            }
        }
    
        return playerData
}
