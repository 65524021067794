import React from 'react'
import DiscordLogo from '../files/DiscordLogo.png';
import ReactPlayer from 'react-player';


export default function TextDesc() {

    
    const youtubeURL = "https://www.youtube.com/watch?v=BPqreO0UaZY";

    return(
        <div style={{margin: '10%', backgroundColor: '#111111'}}>


            <div className="myFAQHeader" style={{paddingTop: '75px'}}> What is OneDropNFTs? </div>
            <div className="myFAQText">
                {/* <div style={{backgroundColor: '#222222', borderRadius: '20px', padding: '20px', textAlign: 'left'}}> */}
                <div className="myFAQContent">
                    An Independent Record Label: we produce digital Vinyls. <br></br>
                    <br></br>
                    That means we do a few things: <br></br>
                    - &nbsp; Work with Professional Artists to make dope art NFTs. <br></br>
                    - &nbsp; Work with Musicians to decentralize their music as NFTs. <br></br>
                    - &nbsp; Release Albums coupled with Pieces of art as album covers and event/band posters. <br></br>
                </div>
            </div>
            

            <div className="myFAQHeader"> Why Should I care? </div>
            <div className="myFAQText">
                <div className="myFAQContent">
                    Five reasons: <br></br>
                    - OneDropNFTs has turned your crypto wallet into your music library. <br></br>
                    - We created a cross-blockchain Metadata standard that will redfine Music NFTs. <br></br>
                    - We are building an open source ecosystem for everyone to take advantage of. <br></br>
                    - We are developing an app to play every NFT made using this standard. <br></br>
                    - Owen has many contacts in the industry to make this vision happen. <br></br>
                </div>
            </div>

            <div className="myFAQHeader"> Who is OneDropNFTs? </div>
            <div className="myFAQText">
                <div className="myFAQContent">
                    Our fully doxxed team has been working in the tech and music industry for combined decades. Don't like what we are doing? Come kick us in the shins yourself. <br></br>
                    Art/Music: <a href="https://twitter.com/OneDropDesignS1" style={{backgroundColor: 'transparent', color: '#4dfff1'}}> Owen Murphy </a>  <br></br>
                    Technology: <a href="https://twitter.com/Bx_user" style={{backgroundColor: 'transparent', color: '#4dfff1'}}> Brendan Harris </a>  <br></br>
                    Community: <a href="https://twitter.com/perpetual_soup" style={{backgroundColor: 'transparent', color: '#4dfff1'}}> Jes </a>  <br></br>
                </div>
            </div>


            <div className="myFAQHeader"> Does the Blockchain hurt the environment? </div>
            <div className="myFAQText">
                <div className="myFAQContent">
                    - Bitcoin and Ethereum use a ton of energy because they use an algorithm called 'Proof Of Work'. <br></br>
                    - OneDrop uses the Solana Network. Each transaction uses less energy than a google search. <br></br>
                    - The environment comes first, which is one of the many reasons we chose to launch on Solana. <br></br>
                    - Distributing Music and Art digitally has a much smaller carbon footprint than standard printing and shipping.  
                </div>
            </div>

            <div className="myFAQHeader"> How do I display digital art? </div>
            <div className="myFAQText">
                <div className="myFAQContent">
                    Use them as phone or desktop backgrounds, Social Media Avatars, or pick up a digital photo frame! <br></br>
                    We recommend <a href="https://www.samsung.com/us/tvs/the-frame/highlights/?cid=sem-mktg-pub-tv-us-google-na-04072021-140213-&ds_e=GOOGLE-cr:0-pl:201014718-&ds_c=FF~Core+Brand_CN~Frame_PH~on_MK~usnat_BS~vd_PR~ctv_SB~frame_PK~cpql_FS~lo_CA~kew_MD~h_KS~ba_MT~exa-&ds_ag=AG~General+Frame_MK~usnat_AT~ta_MD~h_AG~General+Frame_AI~no-&ds_k=samsung+the+frame&gclid=EAIaIQobChMIhrusocL29gIVSHxvBB0emQwQEAAYASAAEgIfcPD_BwE&gclsrc=aw.ds" style={{backgroundColor: 'transparent', color: '#4dfff1'}}>Samsung's The Frame</a>
                    &nbsp; or <a href="https://www.amazon.com/Skylight-Frame-Digital-Picture-Anywhere/dp/B01N7ENHO6/ref=sr_1_3?c=ts&keywords=Digital+Picture+Frames&qid=1648941667&s=photo&sr=1-3&ts_id=525460" style={{backgroundColor: 'transparent', color: '#4dfff1'}}>This Digital Photo Frame</a>
                </div>
            </div>

            <div className="myFAQHeader"> How do I get set up to use the blockchain? </div>
            <div className="myFAQText">
                <div className="myFAQContent">
                    1. We mint on Solana. You can learn more about it <a href="https://www.investopedia.com/solana-5210472" style={{backgroundColor: 'transparent', color: '#4dfff1'}}>HERE</a> <br></br>
                    2. Open up a chrome browser on your desktop/laptop and install the <a href="https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa?hl=en" style={{backgroundColor: 'transparent', color: '#4dfff1'}}>phantom wallet browser extension</a>. Mobile is NOT supported on this website at this time! <br></br>
                    {/* 3. Set up the Phantom Wallet by following <a href="https://www.youtube.com/watch?v=BPqreO0UaZY" style={{backgroundColor: 'transparent', color: '#4dfff1'}}>This Tutorial</a>. <br></br> */}
                    3. Set up the Phantom Wallet by following this tutorial:
                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                        <ReactPlayer url={youtubeURL} style={{width: '50%'}}/>
                    </div>
                    <br></br>
                    4. Fund your wallet using the tutorial above. You should send your wallet some 'Sol' (Solana) tokens through Coinbase, Kraken, Gemini, or FTX. Try not to use other services.<br></br>
                    5. Click on the 'connect wallet' button in the upper right corner. <br></br>
                    6. You're now connected! Congrats on being a crypto hacker!
                </div>
            </div>

            <div className="myFAQHeader"> How do I keep my crypto safe? </div>
            <div className="myFAQText">
                <div className="myFAQContent">
                    - NEVER EVER give out your 12 word seed phrase that you wrote down when you made your wallet. <br></br>
                    - NEVER EVER redeem / visit websites of weird NFTs that you don't recognize in your wallet. <br></br>
                    - Only connect to websites you 100% trust! We at OneDropNFTs are fully open about our identity, which you will find is rare in the crypto space. Come kick us in the shins if we take your money. <br></br>
                    - Keep your seed phrase ON PAPER. Lock it in a fireproof safe, a deposit box, etc. You lose that, you lose everything. <br></br>
                    - We highly recommend you use a hardware wallet for any crypto you hold long term. We suggest using a <a href="https://support.ledger.com/hc/en-us/articles/360016265659-Solana-SOL-?docs=true" style={{backgroundColor: 'transparent', color: '#4dfff1'}}>Ledger Wallet</a>
                </div>
            </div>

            <div className="myFAQHeader"> How do I get in contact? </div>
            <div className="myFAQText">
                <div className="myFAQContent" style={{textAlign: 'center'}}>
                    Are you an artist or musician who wants to work with us? <br></br>
                    Are you a Live Music guru? <br></br>
                    Do you have questions about using the site / funding your wallet? <br></br>
                    Join the Discord Community!
                    
                </div>
            </div>

            <div style={{backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: '75px', padding: '15px'}} > <a href="https://discord.com/invite/Kd5hAD9gNg"> <img src={DiscordLogo} alt="DiscordLink" height='50px'/> </a> </div>
            </div>


            {/* </div> */}
        </div>
    );

};