import React from 'react'
// import OneDropLogo from '../files/OneDropNFT-Token.png';


export default function Title() {

  return (
    <>

        <div style={{marginTop: "-250px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {/* <div>
                <img src={OneDropLogo} alt="OneDropLogo" width="125px" height="125px" />
            </div> */}
            <div className="myTitle" >
            One Drop NFTs
            </div>
            <div className="myTitle" style={{marginTop: '-5px', color: '#aaaaaa', fontSize: 'calc(17px + 1.5vw)'}}>
            Presents:
            </div>
            <div className="myTextOverlay2" style={{marginTop: '20px', color: '#00a3ad', display: 'flex', justifyContent: 'center'}}>
            Art with a Soundtrack
            </div>
        </div>

    </>
  )
}

