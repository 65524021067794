import './App.css';
import DiscordLogo from './files/DiscordLogo.png';
import TwitterLogo from './files/TwitterLogo.png';
import { useEffect, useMemo, useState } from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
import { ThemeProvider, createTheme } from '@material-ui/core';


// ============ My Components ================= //
import P5Sketch from './components/P5Sketch'
// import OneDropLogo from './files/OneDropNFT-Token.png';
import Title from './components/Title'
import Listen from './components/Listen'
import TextDesc from './components/TextDesc'





const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const App = () => {
  const [iOSState, setiOSState] = useState<boolean>(false);

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  useEffect(() => {
    function iOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    //setiOSState(true);
    setiOSState(iOS());
  }, [])

  
  if (iOSState) {
  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
              <P5Sketch />
            </div>
            <Title />
            <Listen />
            <div  className="myTitle2" style={{fontSize: 'calc(12px + 1vw)', marginTop: '-100px', marginBottom: '0px'}} >
              Turn your crypto wallet into your music library.
            </div>
            <div className="myFAQHeader" style={{marginTop: '0px', marginBottom: '120px'}}> Minting Now! <br></br> iOS Not supported</div>
            <div style={{backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '75px'}}>
                <div style={{width: '75px', padding: '15px'}} > <a href="https://discord.com/invite/Kd5hAD9gNg"> <img src={DiscordLogo} alt="DiscordLink" height='50px'/> </a> </div>
                <div style={{width: '75px', padding: '15px'}} > <a href="https://twitter.com/OneDropNFTs"> <img src={TwitterLogo} alt="TwitterLink" height='50px'/> </a> </div>
            </div>
            <TextDesc />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );

  } else {

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
              <P5Sketch />
            </div>
            <Title />
            <Listen />
            <div  className="myTitle2" style={{fontSize: 'calc(12px + 1vw)', marginTop: '-100px', marginBottom: '0px'}} >
              Turn your crypto wallet into your music library.
            </div>
            <div className="myFAQHeader" style={{marginTop: '0px', marginBottom: '120px'}}> Minting Now! </div>
            <Home
              candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
            />
            <div style={{backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '75px'}}>
                <div style={{width: '75px', padding: '15px'}} > <a href="https://discord.com/invite/Kd5hAD9gNg"> <img src={DiscordLogo} alt="DiscordLink" height='50px'/> </a> </div>
                <div style={{width: '75px', padding: '15px'}} > <a href="https://twitter.com/OneDropNFTs"> <img src={TwitterLogo} alt="TwitterLink" height='50px'/> </a> </div>
            </div>
            <TextDesc />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
  }
};

export default App;
