
import '../App.css';
import cwr_OneNightStand from "../files/cwr-OneNightStand.wav";
import cwr_MagickEverySunday from "../files/cwr-MagickEverySunday.wav";
import { musicDataParse } from './AudioMetadataParse'
import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { projectMintAddresses } from '../files/projectMintAddresses'
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import * as web3 from '@solana/web3.js';
import { Connection, programs } from '@metaplex/js';
import { log } from 'console';
const { metadata: { Metadata } } = programs;




export default function Listen(dropdown: any){
  const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
  const connection = new web3.Connection(rpcHost, 'confirmed')

  //const { connection } = useConnection();
  const { wallet, publicKey } = useWallet();
  console.log(`Listen: Wallet: `, wallet)
  console.log(`Listen, PublicKey: `, publicKey)
  //console.log(`Connection: `, connection);

  const [metadata, setMetadata] = useState<any>({});
  const [selectedURL, setSelectedURL] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>("Choose A Song!");
  const [verifyAssets, setVerifyAssets] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);


  useEffect(() => {

    const getMetadata = async () => {
        const nftsMetadata = await Metadata.findDataByOwner(connection, publicKey!);
        //console.log(`Find NFT Data Account By Owner: `, nftsMetadata);
        
        const numberOfWalletNFTs = nftsMetadata.length;
        const metadataURIs = new Array(numberOfWalletNFTs);  // Contains top Level Metadata
        const nftsFetch = new Array(numberOfWalletNFTs);     // Full Metadata Returned 
        const nftsJSON = new Array(numberOfWalletNFTs);      // Full Metadata JSON format

        for (let i=0; i<numberOfWalletNFTs; i++){
            metadataURIs[i] = nftsMetadata[i].data.uri;
            nftsFetch[i] = await fetch(metadataURIs[i]);
            nftsJSON[i] = await nftsFetch[i].json()
        }

        //console.log(`image metadata: `, nftsJSON);

        setMetadata({walletNFTs: nftsMetadata, nftJSON: nftsJSON});
    }
    getMetadata();      
  },[publicKey])
  

    const walletString = JSON.stringify(publicKey);
    
    // ----- NFT data returned from query
    const myData = metadata.nftJSON;
    console.log(`JSON Metadata returned: `, myData);

    // Checking if the 'myData' NFT query object is empty
    let playerData: any = musicDataParse(myData)

    console.log(`PlayerData Parsed: `, playerData)
    console.log(`State Variables: `, selectedURL, selectedName)



    const playerContainer = {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'yellow',
      borderColor: 'pink',
      width: '60%'
    }

    const attributeContainer = {
      display: 'flex',
      backgroundColor: 'purple'
    }



    let trackNames: string[];
    let trackURLs: string[];
  
    console.log(`Player Data: `, playerData);
    console.log(`Player Data length: `, playerData.length);
    
    // if (playerData.length < 1) {
    //   const playerDataDefault = [
    //     {
    //         album: [" to Listen to the full Album", " to Listen to the full Album"],
    //         imageURLs: "https://arweave.net/sU14JOvk-fR_8FNFnTDsTfoGmLVGG3v1_wrBzR-n45E?ext=png",
    //         ipfsURLs: [cwr_OneNightStand, cwr_MagickEverySunday],
    //         musician: ["Own A Messenger of Eden NFT", "Own A Messenger of Eden NFT"],
    //         tracks: [" 1", " 2"]
    //     }
    //   ]
  
    //   trackNames = playerDataDefault[0]?.tracks.map((_: any,i: any,tracknameDefault: any) => playerDataDefault[0]?.musician[i] + ', ' + playerDataDefault[0]?.album[i] + ': ' + tracknameDefault[i]);
    //   trackURLs = playerDataDefault[0]?.ipfsURLs;
    // } else {
    //   trackNames = playerData[0]?.tracks.map((_: any,i: any,trackname: any) => playerData[0]?.musician[i] + ', ' + playerData[0]?.album[i] + ': ' + trackname[i]);
    //   trackURLs = playerData[0]?.ipfsURLs;
    // }
    
    if (playerData.length < 1) {
      playerData = [
        {
            album: [" to Listen to the full Album", " to Listen to the full Album"],
            imageURLs: "https://arweave.net/sU14JOvk-fR_8FNFnTDsTfoGmLVGG3v1_wrBzR-n45E?ext=png",
            ipfsURLs: [cwr_OneNightStand, cwr_MagickEverySunday],
            musician: ["Own A Messenger of Eden NFT", "Own A Messenger of Eden NFT"],
            tracks: [" 1", " 2"]
        }
      ]
  
      trackNames = playerData[0]?.tracks.map((_: any,i: any,tracknameDefault: any) => playerData[0]?.musician[i] + ', ' + playerData[0]?.album[i] + ': ' + tracknameDefault[i]);
      trackURLs = playerData[0]?.ipfsURLs;
    } else {
      trackNames = playerData[0]?.tracks.map((_: any,i: any,trackname: any) => playerData[0]?.musician[i] + ', ' + playerData[0]?.album[i] + ': ' + trackname[i]);
      trackURLs = playerData[0]?.ipfsURLs;
    }

    console.log(`Player Data AFTER: `, playerData);
    console.log(`Player Data length: `, playerData.length);

    return (

      <div style={{display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#AAAAAA', borderWidth: '2px', borderRadius: '5px', margin: '10%', padding: '2%', backgroundColor: '#222222', marginTop: '150px', marginBottom: '150px'}}> 
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginTop: '-50px'}}>
              {/* <div className="myTitle2"> Play Your NFTs</div> */}
              <div className="myTitle2" style={{fontSize: 'calc(12px + 1vw)'}}> Play Your NFTs </div>
              <div className="myFAQText" style={{fontSize: 'calc(8px + 0.5vw)'}}> Directly from your wallet </div>
              <div className="dropdown">
              <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
                {selectedName}
                <span className="fas fa-caret-down"></span>
              </div>
              {isActive && (
              <div className="dropdown-content">
                {trackURLs.map((_,i,list) => (
                  <div onClick={(e) => {setSelectedURL(trackURLs[i]); setSelectedName(trackNames[i]); setIsActive(false); }} className="dropdown-item" >
                  {trackNames[i]}
                  </div>
                ))}
              </div>
              )}
            </div>
              
              
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                  <audio src={selectedURL} controls autoPlay />
              </div>
          </div>

          {/* <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginTop: '20px'}}> */}
              {/* <MyDropDown selectedURL={selectedURL} setSelectedURL={setSelectedURL} selectedName={selectedName} setSelectedName={setSelectedName} playerData={playerData}/> */}

              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', opacity: '100%', margin: '10px'}}>
                <img src={playerData[0]?.imageURLs} height='auto' width='50%'/>
              </div>
          {/* </div> */}
      </div>
  );



    // const playerData = [
    //   {
    //        album: ["MoE", "MoE", "MoE"],
    //        imageURLs: "https://arweave.net/ssc14af7mftEbb-9E67tarwrSEbengFjQ1ryOMh57qA?ext=png",
    //        ipfsURLs: [cwr_DirtyLittleLies, cwr_OneNightStand, cwr_MagickEverySunday],
    //        musician: ["crypto WAVE radio", "crypto WAVE radio", "crypto WAVE radio"],
    //        tracks: ["Dirty Little Lies", "One Night Stand", "Magick Every Sunday"]
    //   },
    //   {
    //     album: ["MoE2", "MoE2", "MoE2"],
    //     imageURLs: "https://arweave.net/ssc14af7mftEbb-9E67tarwrSEbengFjQ1ryOMh57qA?ext=png",
    //     ipfsURLs: [cwr_DirtyLittleLies, cwr_OneNightStand, cwr_MagickEverySunday],
    //     musician: ["crypto WAVE radio2", "crypto WAVE radio2", "crypto WAVE radio2"],
    //     tracks: ["Dirty Little Lies2", "One Night Stand2", "Magick Every Sunday2"]
    //   }
    // ]






  // ================ UNCOMMENT FOR THE REAL SITE OPERATION ============= ~~~~
  //   return (
  //     <PageContainer> 

  //         <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginTop: '-20px'}}>
  //             {/* <audio src={cryptowav} controls autoPlay/> */}
  //             <div className="myTitle2"> Play Your NFTs</div>
  //             <div className="myFAQText"> Hold a Messenger of Eden to play your album</div>

  //         </div>



  //         <div className="myFAQText"> Introducing Solana's first modular NFT album art and music solution. <br></br>
  //         Individual track audio listening experience, ownership, and cover art Integration.  <br></br>
  //         First Mint Going Live on May 25th. <br></br> 
  //         </div>



  //     </PageContainer>
  // );



}



    //const mydataoutput = metadata.nftJSON;
    //console.log(`JSON Metadata : `, mydataoutput[9]);


    //musicDataParse(metadata.nftJSON);
    
    // const numberOfWalletNFTs = mydataoutput.length;
    // const metadataURIs = new Array(numberOfWalletNFTs);  // Contains top Level Metadata
    // const nftsFetch = new Array(numberOfWalletNFTs);     // Full Metadata Returned 
    // const nftsJSON = new Array(numberOfWalletNFTs);      // Full Metadata JSON format

    // console.log(`Number of NFTs: `, numberOfWalletNFTs); 



        //      <div>
        //   <div>
        //    {playerData?.map((_,i,list) => ( 
        //       <div style={attributeContainer} key={playerData[i].musician + i}>
        //         {playerData[i].musician} <br></br>
        //         {playerData[i].album}
        //         {playerData[i].tracks}
        //         {playerData[i].ipfsURLs}
        //       </div>
        //     ))} 
        //   </div>
        // </div>



      //   const rawMetadata = [
      //     {
      //       "name": "Messengers of Eden #1",
      //       "symbol": "MOE", 
      //       "description": "999 Messengers of Eden were sent to the blockchain to create a community around the love of music and sharing art.",
      //       "seller_fee_basis_points": 1000, 
      //       "image": "https://arweave.net/-HCWdVnwH-GfOdcv_cmE9t8zZds5eXKeq76XMfPVv38?ext=png",
      //       "external_url": "https://onedropnfts.com", 
      //       "edition": 1,
      //       "track1": "CryptoWaveRadio,, Messengers of Eden:: Song Name1 &&bafybeiax3uu4k3asbioftha6qoohvygma2a5zsc6qnmnxep6zr2cl7qo24",
      //       "track2": "CryptoWaveRadio,, Messengers of Eden:: Song Name2 &&bafybeihwlexgqbcnxaskesl22fhvw7xdgewz5zfhta27v4wcqp2kbg5i2a",
      //       "track3": "CryptoWaveRadio,, Messengers of Eden:: Song Name3 &&bafybeiax3uu4k3asbioftha6qoohvygma2a5zsc6qnmnxep6zr2cl7qo24",
      //       "track4": "CryptoWaveRadio,, Messengers of Eden:: Song Name4 &&bafybeihwlexgqbcnxaskesl22fhvw7xdgewz5zfhta27v4wcqp2kbg5i2a",
      //     },
      //     {
      //       "name": "MyDogShit Collection",
      //       "symbol": "MDC", 
      //       "description": "Some stupid description",
      //       "seller_fee_basis_points": 1000, 
      //       "image": "https://arweave.net/-HCWdVnwH-GfOdcv_cmE9t8zZds5eXKeq76XMfPVv38?ext=png",
      //       "external_url": "https://onedropnfts.com", 
      //       "edition": 1,
      //       "track1": "Mydogshit Band,, Eat Shit:: SongName1 &&bafybeiax3uu4k3asbioftha6qoohvygma2a5zsc6qnmnxep6zr2cl7qo24",
      //       "track2": "Mydogshit Band,, Eat Shit:: SongName2 &&bafybeihwlexgqbcnxaskesl22fhvw7xdgewz5zfhta27v4wcqp2kbg5i2a",
      //       "track3": "Mydogshit Band,, Eat Shit:: SongName3 &&bafybeiax3uu4k3asbioftha6qoohvygma2a5zsc6qnmnxep6zr2cl7qo24",
      //       "track4": "Mydogshit Band,, Eat Shit:: SongName4 &&bafybeihwlexgqbcnxaskesl22fhvw7xdgewz5zfhta27v4wcqp2kbg5i2a",
      //       "track5": "Mydogshit Band,, Eat Shit:: SongName5 &&bafybeiax3uu4k3asbioftha6qoohvygma2a5zsc6qnmnxep6zr2cl7qo24",
      //       "track6": "Mydogshit Band,, Eat Shit:: SongName6 &&bafybeihwlexgqbcnxaskesl22fhvw7xdgewz5zfhta27v4wcqp2kbg5i2a",
      //   },
      //   {
      //     "name": "MyTrash",
      //     "symbol": "MT", 
      //     "description": "Some stupid description",
      //     "seller_fee_basis_points": 1000, 
      //     "image": "https://arweave.net/-HCWdVnwH-GfOdcv_cmE9t8zZds5eXKeq76XMfPVv38?ext=png",
      //     "external_url": "https://onedropnfts.com", 
      //     "edition": 1
      // }
      // ]       